<template>
  <footer id="footer" class="footer--style-1">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-auto">
          <div class="footer__item">
            <Logo />
          </div>
        </div>
        <div class="col-12 col-sm">
          <div class="row align-items-md-center no-gutters">
            <div class="col-12 col-md">
              <div class="footer__item">
                <address>
                  <p />
                  799 CH. ST-GEORGES ST-TÉLESPHORE Québec J0P1G0 Canada
                  <p>
                    <a href="mailto:pillarhill-farm@hotmail.com">pillarhill-farm@hotmail.com</a>
                  </p>
                </address>
              </div>
            </div>

            <div class="col-12 col-md-auto">
              <div class="footer__item">
                <div class="social-btns">
                  <!--
                  <a href="#"><i class="fontello-twitter" /></a>
                  <a href="#"><i class="fontello-facebook" /></a>
                  <a href="#"><i class="fontello-linkedin-squared" /></a>
                  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row flex-lg-row-reverse">
        <div class="col-12 col-lg-6">
          <div class="footer__item">
            <nav id="footer__navigation" class="navigation text-lg-right">
              <ul>
                <!--
                <li class="active">
                  <a href="/">Home</a>
                </li>
                -->
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from '~/components/Logo.vue'

export default {
  name: 'Footer',
  components: {
    Logo
  },
  head () {
    return {
      script: [
        { src: 'https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js' }
      ]
    }
  }
}
</script>

<style scoped>

</style>
