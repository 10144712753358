import AOS from '../assets/libs/aos/aos'

AOS.init({
  offset: 120,
  delay: 100,
  duration: 450,
  easing: 'ease-in-out-quad',
  once: true,
  disable: 'mobile'
})
