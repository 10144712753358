<template>
  <div
    class="start-screen__content__item start-screen__content__item--1  align-items-center"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-xl-8">
          <div class="__name">
            Pillar Hill
          </div>

          <h2 class="__title text-white">
            Feeding the planet for 4 generations
          </h2>

          <p class="text-center">
            &nbsp;
          </p>

          <p class="text-center mt-5 mt-md-10">
            <span class="d-none d-sm-block"><a class="custom-btn custom-btn--big custom-btn--style-3" href="#">Contact Us</a></span>

            <span class="d-block d-sm-none"><a class="custom-btn custom-btn--small custom-btn--style-3" href="#">Contact Us</a></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartSlide'
}
</script>

<style scoped>

</style>
