<template>
  <div id="app">
    <Header />

    <StartScreen />

    <nuxt />

    <ContactForm />

    <Footer />

    <div id="btn-to-top-wrap">
      <a id="btn-to-top" class="circled" href="javascript:void(0);" data-visible-offset="800" />
    </div>
    <client-only>
      <script>window.jQuery || document.write('<script src="/js/jquery-2.2.4.min.js"><\/script>')</script>
    </client-only>
  </div>
</template>

<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import StartScreen from '../components/StartScreen'
import ContactForm from '../components/ContactForm'
export default {
  components: {
    ContactForm,
    StartScreen,
    Header,
    Footer
  }
}
</script>
