<template>
  <!-- start section -->
  <section id="contact" class="section section--dark-bg">
    <div class="container">
      <div class="section-heading section-heading--center section-heading--white">
        <h2 class="__title">
          Contact Us
        </h2>
      </div>
      <form
        class="contact-form js-contact-form"
        @submit.prevent="submitForm"
      >
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="input-wrp">
              <input
                v-model="contact_name"
                class="textfield"
                name="name"
                type="text"
                placeholder="Name"
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="input-wrp">
              <input
                v-model="contact_email"
                class="textfield"
                name="email"
                type="text"
                placeholder="Email"
              >
            </div>
          </div>
        </div>

        <div class="input-wrp">
          <textarea
            v-model="contact_message"
            class="textfield"
            name="message"
            placeholder="Message"
          />
        </div>

        <button class="custom-btn custom-btn--medium custom-btn--style-3 wide" type="submit">
          Send
        </button>
      </form>
    </div>
  </section>
  <!-- end section -->
</template>

<script>
export default {
  name: 'ContactForm',
  data () {
    return {
      errors: [],
      contact_name: '',
      contact_email: '',
      contact_message: ''
    }
  },
  async mounted () {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      console.error(e)
    }
  },
  beforeDestroy () {
    this.$recaptcha.destroy()
  },
  methods: {
    async submitForm () {
      const data = new FormData()

      data.append('name', this.contact_name)
      data.append('email', this.contact_email)
      data.append('message', this.contact_message)

      try {
        const token = await this.$recaptcha.execute('submit')
        data.append('token', token)

        await this.$axios.post(
          'https://api.pillarhill.ca/send/',
          data
        ).then(() => {
          this.contact_name = ''
          this.contact_email = ''
          this.contact_message = ''
        })
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
